<template>
  <v-card elevation="4" tile>
    <v-card-title class="pa-0">
      <v-toolbar color="#083272" dark>
        <v-btn icon class="d-none d-md-inline" @click="toggleCollapse">
          <v-icon v-if="!isCollapse">mdi-arrow-collapse-left</v-icon>
          <v-icon v-if="isCollapse">mdi-arrow-expand-right</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-bold suptitle">
            {{ project.contract_name }}
          </h3>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          color="white"
          light
          @click="newCase"
          tile
          v-if="is_active && !isReadOnly"
        >
          <v-icon>mdi-plus</v-icon>
          New Case
        </v-btn>

        <v-btn
          color="error"
          light
          tile
          v-if="!is_active"
          @click="expiredAlert()"
        >
          EXPIRED
          <v-icon class="ml-2">mdi-comment-alert</v-icon>
        </v-btn>

        <v-btn color="info" light tile v-if="isReadOnly" @click="clickReadOnly">
          READONLY
          <v-icon class="ml-2">mdi-clock-outline</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs v-model="selectedTab">
            <v-tabs-slider color="white"></v-tabs-slider>
            <v-tab
              v-for="item in tabItems"
              :key="item.id"
              @click="changeTab(item)"
            >
              {{ item.text }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-container fluid class="px-0">
        <v-row>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              v-model="searchBox"
              placeholder="Search keyword"
              outlined
              hide-details
              prepend-icon="mdi-magnify"
              dense
              flat
            ></v-text-field>
          </v-col>
          <v-col>
            <v-switch
              hide-details=""
              class="ma-0 mt-1"
              label="L1 Only"
              v-model="L1onlyFilter"
              v-if="isAdmin"
            ></v-switch>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="pb-0" v-if="isAdmin">
            <v-btn color="#FF4D00" dark tile @click="downloadExcel">
              <v-icon dark> mdi-download </v-icon> export
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <DataGrid
              :data="filteredCases"
              :filterable="false"
              :virtualScroll="false"
              :clickToEdit="true"
              editMode="cell"
              :columnResizing="true"
              style="width: 100%; min-height: 500px"
            >
              <GridColumnGroup>
                <GridHeaderRow>
                  <GridColumn
                    field="case_id"
                    title="ID"
                    align="center"
                    halign="center"
                    :sortable="true"
                    width="70"
                  >
                    <template slot="body" slot-scope="scope">
                      <a @click="clickCase(scope.row)">{{
                        scope.row.case_id
                      }}</a>
                    </template>
                  </GridColumn>
                  <GridColumn
                    field="TB_PRST_PRIORITY_LEVEL.level_name"
                    title="Priority"
                    align="center"
                    halign="center"
                    :sortable="true"
                    width="50"
                  >
                    <template slot="body" slot-scope="scope">
                      {{ scope.row.TB_PRST_PRIORITY_LEVEL.level_name }}
                    </template>
                  </GridColumn>
                  <GridColumn
                    field="TB_PRST_CASE_STATUS_LIST.status_name"
                    title="Status"
                    align="center"
                    halign="center"
                    :sortable="true"
                    width="50"
                  >
                    <template slot="body" slot-scope="scope">
                      {{ scope.row.TB_PRST_CASE_STATUS_LIST.status_name }}
                    </template>
                  </GridColumn>
                  <GridColumn
                    field="case_is_escalation"
                    title="L1 Only"
                    align="center"
                    halign="center"
                    :sortable="true"
                    width="50"
                    v-if="isAdmin"
                  >
                    <template slot="body" slot-scope="scope">
                      {{ scope.row.case_is_escalation ? "N" : "Y" }}
                    </template>
                  </GridColumn>
                  <GridColumn
                    field="case_type"
                    title="Type"
                    align="center"
                    halign="center"
                    :sortable="true"
                    width="70"
                  >
                  </GridColumn>
                  <GridColumn
                    field="TB_INFO_PRODUCT_LIST.product_name"
                    title="Product"
                    align="center"
                    halign="center"
                    :sortable="true"
                    width="90"
                  >
                    <template slot="body" slot-scope="scope">
                      {{ scope.row.TB_INFO_PRODUCT_LIST.product_name }}
                    </template>
                  </GridColumn>
                  <GridColumn
                    field="case_subject"
                    title="Title"
                    align="left"
                    halign="center"
                    :sortable="true"
                    width="250"
                  >
                    <template slot="body" slot-scope="scope">
                      {{ htmlToText(scope.row.case_subject) }}
                    </template>
                  </GridColumn>
                  <GridColumn
                    field="case_open_dttm"
                    title="Open Datetime"
                    align="center"
                    halign="center"
                    :sortable="true"
                    width="90"
                  >
                    <template slot="body" slot-scope="scope">
                      {{ koreaTime(scope.row.case_open_dttm) }}
                    </template>
                  </GridColumn>
                  <GridColumn
                    field="case_close_dttm"
                    title="Close Datetime"
                    align="center"
                    halign="center"
                    :sortable="true"
                    width="90"
                    v-if="selectedTab == 2 || selectedTab == 1"
                  >
                    <template slot="body" slot-scope="scope">
                      <div v-if="scope.row.case_close_dttm">
                        {{ koreaTime(scope.row.case_close_dttm) }}
                      </div>
                    </template>
                  </GridColumn>
                </GridHeaderRow>
              </GridColumnGroup>
            </DataGrid>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { getOpendCase, getAllCase, getClosedCase } from "@/api/Case";
import { downloadExcel } from "./utils/downloadExcel";
import { htmlToText } from "@/utils/htmlToText";
import * as moment from "moment";
export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    is_active: {
      type: Boolean,
      required: true,
    },
    onCollapse: {
      type: Function,
      required: true,
    },
    isCollapse: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      tabItems: [
        {
          id: 0,
          text: "OPEN CASES",
        },
        {
          id: 1,
          text: "ALL CASES",
        },
        {
          id: 2,
          text: "CLOSED CASES",
        },
      ],
      caseHeaders: [
        { text: "ID", value: "case_id" },
        { text: "Priority", value: "TB_PRST_PRIORITY_LEVEL.level_name" },
        { text: "Status", value: "TB_PRST_CASE_STATUS_LIST.status_name" },
        { text: "Type", value: "case_type" },
        { text: "Product", value: "TB_INFO_PRODUCT_LIST.product_name" },
        { text: "Title", value: "case_subject" },
      ],
      selectedTab: 0,
      searchBox: "",
      caseList: [],
      L1onlyFilter: false,
      htmlToText,
    };
  },
  mounted() {
    this.getCases();
  },
  watch: {
    project() {
      this.getCases();
    },
    company() {
      this.getCases();
    },
  },
  computed: {
    filteredCases() {
      console.log(this.caseList);
      return this.caseList.filter(this.casefilter);
    },
    myInfo() {
      return JSON.parse(this.$store.getters["userInfo/userData"]);
    },
    isAdmin() {
      return this.myInfo.user_level > 3;
    },
    isReadOnly() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (!publicSetting.READONLY_ON) return false;
      if (this.isAdmin) return false;
      const readonly_dow = publicSetting.READONLY_DOW.split(",");
      console.log();
      const readonly_end_time = moment(
        moment().format("yyyy-MM-DD") + " " + publicSetting.READONLY_END_TIME
      );
      const readonly_start_time = moment(
        moment().format("yyyy-MM-DD") + " " + publicSetting.READONLY_START_TIME
      );
      const today = moment();

      const is_inTime =
        readonly_end_time > today && readonly_start_time < today;
      const is_inDow = readonly_dow.find((e) => e == today.days()) != null;

      return !(is_inTime && is_inDow);
    },
  },
  methods: {
    koreaTime(dttm) {
      return moment(dttm).format("yyyy-MM-DD HH:mm:ss");
    },
    casefilter(case_struct) {
      console.log(case_struct);
      const keyword = this.searchBox.toUpperCase();
      if (this.L1onlyFilter) {
        return case_struct.case_is_escalation == 0;
      }
      if (case_struct.case_id.toUpperCase().indexOf(keyword) != -1) {
        return true;
      }
      if (case_struct.case_type.toUpperCase().indexOf(keyword) != -1) {
        return true;
      }
      if (case_struct.case_subject.toUpperCase().indexOf(keyword) != -1) {
        return true;
      }
      if (case_struct.TB_INFO_PRODUCT_LIST) {
        if (
          case_struct.TB_INFO_PRODUCT_LIST.product_name
            .toUpperCase()
            .indexOf(keyword) != -1
        ) {
          return true;
        }
      }

      return false;
    },
    expiredAlert() {
      alert("만료된 프로젝트 입니다. 관리자에게 문의하십시오.");
    },
    clickReadOnly() {
      alert("지금은 읽기 전용만 가능한 시간입니다.");
    },
    downloadExcel() {
      downloadExcel(
        this.caseList,
        this.company.company_name,
        this.project.contract_name
      );
    },
    clickCase(item) {
      this.$store.commit("mainDash/updateSelection", {
        selectedCompany: this.company,
        selectedContract: this.project,
      });
      this.$router.push(`/case/${item.case_uuid}`);
    },
    get_subject(item) {
      if (item.case_type == "Technical" || item.case_type == "Tech") {
        return item.TB_DATA_CASE_TECH_LIST.case_subject;
      }
      if (item.case_type == "Fulfillment" || item.case_type == "Fulfill") {
        console.log(item);
        return item.TB_DATA_CASE_FULFILL_LIST.case_subject;
      }
      if (item.case_type == "Administrative" || item.case_type == "Admst") {
        return item.TB_DATA_CASE_ADMST_LIST.case_subject;
      }
    },
    get_priority(item) {
      if (item.case_priority == 0) return "에러";
      if (item.case_priority == 1) return "일반";
      if (item.case_priority == 2) return "중요";
      if (item.case_priority == 3) return "긴급";
      return item.case_priority;
    },
    newCase() {
      this.$store.commit("mainDash/updateSelection", {
        selectedCompany: this.company,
        selectedContract: this.project,
      });
      this.$router.push("/case/new");
    },
    async changeTab(item) {
      this.selectedTab = item.id;
      await this.getCases();
    },
    async getCases() {
      this.caseList = [];
      if (this.selectedTab == 0) {
        const data = await getOpendCase(this.project.contract_uuid);
        if (data.success) {
          this.caseList = data.data;
        }
      }
      if (this.selectedTab == 1) {
        const data = await getAllCase(this.project.contract_uuid);
        if (data.success) {
          this.caseList = data.data;
        }
      }
      if (this.selectedTab == 2) {
        const data = await getClosedCase(this.project.contract_uuid);
        if (data.success) {
          this.caseList = data.data;
        }
      }
    },
    toggleCollapse() {
      this.onCollapse();
    },
  },
};
</script>

<style scoped>
.datagrid-cell {
  height: auto;
  white-space: pre-wrap;
}
.textbox textarea.textbox-text {
  white-space: pre-wrap;
}
</style>
