<template>
  <v-container class="pt-12" fluid>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-container v-if="post !== null">
          <v-row>
            <v-col cols="12" class="text-left py-0">
              <v-chip :color="getPostTypeColor(post.post_type)" dark
                >{{ post.post_type }}
              </v-chip>
            </v-col>
            <v-col cols="12"><h1 v-html="post.post_title"></h1></v-col>
            <!-- <v-col cols="12" class="text-right py-1">
              <span>정재완(jaewan@jwmsg.me)</span>
            </v-col> -->

            <v-col cols="12" class="text-right py-1">
              <span>{{ fidDatetime(post.createdAt) }}</span>
            </v-col>
          </v-row>
          <v-divider class="py-2 my-3"></v-divider>
          <v-row>
            <v-col style="min-height: 400px">
              <div id="contentbox" v-html="post.post_content"></div>
            </v-col>
          </v-row>

          <v-divider class="py-2 my-3"></v-divider>
          <v-row>
            <v-col>
              <p class="pa-0 ma-0 mb-1 font-weight-bold">Tags</p>
              <v-card class="pa-0" tile elevation="0" outlined min-height="35">
                <v-card-text class="pa-0">
                  <v-layout>
                    <v-col cols="auto" class="pa-1">
                      <v-chip
                        small
                        v-for="tag in post.TB_POST_TAGs"
                        :key="tag.post_tag_id"
                        class="mx-1"
                      >
                        {{ tag.post_tag }}
                      </v-chip>
                    </v-col>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-divider class="py-2 my-3"></v-divider>
          <v-row>
            <v-col cols="auto" class="py-0 pt-3">
              <p class="pa-0 ma-0 mb-1 font-weight-bold">Attachment</p>
            </v-col>
            <v-col cols="12">
              <DataGrid
                :data="post.attachments"
                :filterable="false"
                :pageSize="5"
                :pagination="true"
                :virtualScroll="true"
                :clickToEdit="true"
                :columnResizing="true"
                style="width: 100%"
              >
                <GridColumnGroup>
                  <GridHeaderRow>
                    <GridColumn
                      field="file_name"
                      title="File Name"
                      align="center"
                      :sortable="true"
                    >
                    </GridColumn>
                    <GridColumn
                      field="file_size"
                      title="File Size"
                      align="center"
                      :sortable="true"
                    >
                    </GridColumn>
                    <GridColumn
                      field="file_mimetype"
                      title="Mime Type"
                      align="center"
                      :sortable="true"
                    >
                    </GridColumn>
                  </GridHeaderRow>
                </GridColumnGroup>
                <GridColumnGroup :frozen="true" width="400" align="right">
                  <GridHeaderRow>
                    <GridColumn
                      field="createdAt"
                      title="Created Time"
                      align="center"
                      :sortable="true"
                      size="200"
                    >
                      <template slot="body" slot-scope="scope">
                        {{ toKorTime(scope.row.createdAt) }}
                      </template>
                    </GridColumn>

                    <GridColumn
                      title="Option"
                      :filterable="false"
                      align="center"
                      size="200"
                    >
                      <template slot="body" slot-scope="scope">
                        <LinkButton
                          iconCls="icon-save"
                          class="mx-1"
                          @click="download(scope.row)"
                          :disabled="btnLoading"
                        >
                          Download
                        </LinkButton>
                      </template>
                    </GridColumn>
                  </GridHeaderRow>
                </GridColumnGroup>
              </DataGrid>
            </v-col>
          </v-row>
          <v-row v-if="isAdmin">
            <v-col
              v-for="read in readCntList"
              :key="read.company_uuid"
              cols="12"
              class="py-1"
            >
              <v-chip> {{ read.company_name }} : {{ read.cnt }} </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <v-btn tile color="#FF4D00" dark @click="backToList">
                <v-icon class="mr-2">mdi-post-outline</v-icon>
                목록으로
              </v-btn>
            </v-col>
            <v-col v-if="isAdmin" cols="auto" class="ml-auto">
              <v-btn tile color="error" dark @click="delPosts">
                <v-icon class="mr-2">mdi-delete-outline</v-icon>
                삭제
              </v-btn>
            </v-col>
            <v-col v-if="isAdmin" cols="auto">
              <v-btn tile color="info" dark @click="goToEdit">
                <v-icon class="mr-2">mdi-pencil-outline</v-icon>
                수정
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getPosts, readPosts, delPosts, getPartnerReadCnt } from "@/api/Post";
import { download } from "@/api/Files";
import moment from "moment";
export default {
  props: {
    post_id: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.getPosts();
    this.readPosts();
    if (this.isAdmin) {
      this.getPartnerReadCnt();
    }
  },
  methods: {
    toKorTime(time) {
      const date = new Date(time);
      return date.toLocaleString("Seoul");
    },
    async download(item) {
      this.btnLoading = true;
      alert(
        "파일 용량이 클수록 오래 기다려 주셔야 합니다.\n다운로드 로딩이 끝날때 까지 대기해 주시기 바랍니다."
      );
      await download(item.file_uuid);
      this.btnLoading = false;
    },
    backToList() {
      this.$router.push(`/posts/list`);
    },
    goToEdit() {
      this.$router.push(`/posts/Editor/${this.post_id}`);
    },
    async getPartnerReadCnt() {
      const res = await getPartnerReadCnt({ post_id: this.post_id });
      if (res.success) {
        this.readCntList = Array.from(res.data);
      }
    },
    async delPosts() {
      const data = await delPosts({ post_id: this.post_id });
      if (data.success) {
        alert("삭제했습니다.");
        this.$router.push("/posts");
      }
    },
    async getPosts() {
      const data = await getPosts({ post_id: this.post_id });
      if (data.success) {
        if (data.data.length == 0) {
          alert("존재하지 않는 Post 입니다.");
          this.$router.go(-1);
        }
        this.post = data.data[0];
        this.post.attachments = this.post.TB_JOIN_POST_FILEs.map(
          (e) => e.TB_DATA_FILE_LIST
        ).filter((e) => e != null);
      }
    },
    async readPosts() {
      await readPosts({ post_id: this.post_id });
    },
    fidDatetime(datetime) {
      return moment(datetime).format("yyyy-MM-DD HH:mm:ss");
    },
    getPostTypeColor(post_type) {
      if (post_type === "Notice") return "error";
      if (post_type === "Announcement") return "indigo";
      if (post_type === "KnowledgeBase") return "warning";
      if (post_type === "Article") return "info";
    },
  },
  computed: {
    myInfo() {
      return JSON.parse(this.$store.getters["userInfo/userData"]);
    },
    isAdmin() {
      return this.myInfo.user_level > 3;
    },
  },
  data() {
    return { post: null, tags: [], btnLoading: false, readCntList: [] };
  },
};
</script>

<style>
#contentbox p:after {
  content: "";
  display: inline-block;
  width: 0px;
}
</style>
