import * as Xlsx from "xlsx";
import * as moment from "moment";
import store from "@/store";

const get_subject = (item) => {
  if (item.case_type == "Technical" || item.case_type == "Tech") {
    return item.TB_DATA_CASE_TECH_LIST.case_subject;
  }
  if (item.case_type == "Fulfillment" || item.case_type == "Fulfill") {
    console.log(item);
    return item.TB_DATA_CASE_FULFILL_LIST.case_subject;
  }
  if (item.case_type == "Administrative" || item.case_type == "Admst") {
    return item.TB_DATA_CASE_ADMST_LIST.case_subject;
  }
};

export const downloadExcel = (caseList, username) => {
  const output = caseList.map((e) => {
    const ret = {
      CaseID: e.case_id,
      AccountID: e.TB_INFO_COMPANY_LIST?.company_account_number,
      Customer: e.TB_INFO_COMPANY_LIST?.company_name,
      Project: e.TB_INFO_CONTRACT_LIST?.contract_name,
      Priority: e.TB_PRST_PRIORITY_LEVEL?.level_name,
      Status: e.TB_PRST_CASE_STATUS_LIST?.status_name,
      CaseType: e.case_type,
      Product: e.TB_INFO_PRODUCT_LIST?.product_name,
      Version: e.case_product_version,
      Title: get_subject(e),
      Escalation: null,
      OpenCaseDttm: moment(e.case_open_dttm).format("yyyy-MM-DD HH:mm:ss"),
      CloseCaseDttm: e.case_close_dttm
        ? moment(e.case_close_dttm).format("yyyy-MM-DD HH:mm:ss")
        : "",
      ClientMail: e.case_client_user_mail,
      ClientName: e.case_client_user_name,
      ClientPhone: e.case_client_user_phone,
      WriterMail:
        e.TB_USER_ACCOUNT_LIST?.user_mail_id +
        "@" +
        e.TB_USER_ACCOUNT_LIST?.user_mail_domain,
      WriterName:
        e.TB_USER_ACCOUNT_LIST?.user_name_first_eng +
        " " +
        e.TB_USER_ACCOUNT_LIST?.user_name_last_eng,
      WriterPhone: e.TB_USER_ACCOUNT_LIST?.user_phone_number,
    };
    const myInfo = JSON.parse(store.getters["userInfo/userData"]);
    if (myInfo.user_level > 3) {
      ret.Escalation = e.case_is_escalation ? "L2" : "L1 Only";
    } else {
      delete ret.Escalation;
    }
    return ret;
  });
  const workBook = Xlsx.utils.book_new();
  const workSheet = Xlsx.utils.json_to_sheet(output);
  Xlsx.utils.book_append_sheet(workBook, workSheet, "sheet1");
  Xlsx.writeFile(workBook, `${username}-${Date.now()}.xlsx`);
};
