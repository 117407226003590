<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>관리자 - 회사 등록 관리</h1>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="#FF4D00" dark block @click="$router.push('/admin')">
          BACK
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">Company List</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" light @click="newCompanyBtn">
              <v-icon>mdi-plus</v-icon>
              New Company
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="companySearch"
                    append-icon="mdi-magnify"
                    label="회사 검색"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="companyHeader"
                    :items="companyList"
                    :search="companySearch"
                    class="elevation-0"
                    dense
                    :items-per-page="15"
                    height="500px"
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small class="mr-2" @click="allocUsers(item)">
                        mdi-account
                      </v-icon>
                      <v-icon small class="mr-2" @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                      <v-icon small class="mr-2" @click="downloadItem(item)">
                        mdi-download
                      </v-icon>
                    </template>
                    <template v-slot:item.company_level="{ item }">
                      {{ getLevelName(item.company_level) }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          <h5>삭제시 복구가 불가능 합니다.<br />정말 삭제 하시겠습니까?</h5>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteItemConfirm">
            삭제
          </v-btn>
          <v-btn color="blue darken-1" text @click="closeDelete">취소</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewCompany" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">New Company</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pb-2">
              <v-col cols="12"><h2>Company Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="Account Number (없을 시 공백)"
                  class="px-1 pt-0"
                  v-model="company_frm.company_account_number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="회사명"
                  class="px-1 pt-0"
                  v-model="company_frm.company_name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="비고"
                  class="px-1 pt-0"
                  v-model="company_frm.company_extends"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="company_frm.company_level"
                  outlined
                  light
                  dense
                  hide-details
                  label="권한 선택"
                  :items="companyLevelList"
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="#FF4D00" dark block @click="newCompanySaveBtn">
                  SAVE
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditCompany" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">Edit Company</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pb-2">
              <v-col cols="12"><h2>Company Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="Account Number (없을 시 공백)"
                  class="px-1 pt-0"
                  v-model="edit_company_frm.company_account_number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="회사명"
                  class="px-1 pt-0"
                  v-model="edit_company_frm.company_name"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="edit_company_frm.company_level"
                  outlined
                  light
                  dense
                  hide-details
                  label="권한 선택"
                  :items="companyLevelList"
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="비고"
                  class="px-1 pt-0"
                  v-model="edit_company_frm.company_extends"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="#FF4D00" dark block @click="editCompanySaveBtn">
                  SAVE
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAllocUsers" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">사용자 할당 목록</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" light @click="newAllocUserDialog">
              <v-icon>mdi-plus</v-icon>
              New User
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pb-2">
              <v-col cols="12"><h2>Company Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="회사명"
                  class="px-1 pt-0"
                  readonly
                  v-model="comapny_account_alloc_item.company_name"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"><h2>할당된 사용자 목록</h2></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="allocatedHeader"
                  :items="allocatedUsers"
                  class="elevation-0"
                  dense
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="deleteAllocUser(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn
                  color="#FF4D00"
                  dark
                  block
                  @click="dialogAllocUsers = false"
                >
                  CLOSE <v-icon small class="ml-2"> mdi-close </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAllocUsers_unalloc" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">사용자 할당하기</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row>
              <v-col cols="12"><h2>사용자 목록</h2></v-col>
            </v-row>
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="accountSearch"
                    append-icon="mdi-magnify"
                    label="사용자 검색"
                    single-line
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="accountHeader"
                  :items="accountList"
                  :search="accountSearch"
                  class="elevation-0"
                  dense
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="newAllocUser(item)">
                      mdi-plus
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn
                  color="#FF4D00"
                  dark
                  block
                  @click="dialogAllocUsers_unalloc = false"
                >
                  CLOSE <v-icon small class="ml-2"> mdi-close </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getCompany,
  delCompany,
  newCompany,
  putCompany,
  getAllocUser,
  delAllocUser,
  newAllocUser,
} from "@/api/Admin/Company";
import { getAccounts } from "@/api/Admin/Accounts";
import { getCompanyLevel } from "@/api/Presets";
import { getCaseParams } from "@/api/Case";
import randomstring from "randomstring";
import { downloadExcel } from "./utils/downloadExcelForPartner";
export default {
  mounted() {
    this.getCompany();
    this.getCompanyLevel();
  },
  methods: {
    getLevelName(level) {
      const company_level = this.companyLevelList.find(
        (e) => e.company_level == level
      );
      if (company_level != null) return company_level.level_name;
    },
    async getCompany() {
      const data = await getCompany();
      this.companyList = [];
      if (data.success) {
        this.companyList = data.data;
      }
    },
    async getAccounts() {
      const data = await getAccounts();
      this.accountList = [];
      const allocated = this.allocatedUsers.map((e) => e.user_uuid);
      if (data.success) {
        this.accountList = data.data.filter(
          (e) => !allocated.includes(e.user_uuid)
        );
      }
    },
    async getCompanyLevel() {
      const data = await getCompanyLevel();
      this.companyLevelList = [];
      if (data.success) {
        this.companyLevelList = data.data;
        for (const level of this.companyLevelList) {
          level.text = level.level_name;
          level.value = level.company_level;
        }
      }
    },

    deleteItem(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    async downloadItem(item) {
      const accounts = await getAccounts();
      const allocUser = await getAllocUser(item.company_uuid);
      const table = {};
      if (allocUser.success) {
        const allocUsers = Array.from(allocUser.data);
        const allocated = allocUsers.map((e) => e.user_uuid);
        const accountList = accounts.data.filter((e) =>
          allocated.includes(e.user_uuid)
        );
        console.log(accountList);
        for (const user of accountList) {
          const res = await getCaseParams({
            case_write_user_uuid: user.user_uuid,
            case_company_uuid: item.company_uuid,
          });
          if (res.success) {
            if (
              table[`${user.user_name_last_kor + user.user_name_first_kor}`]
            ) {
              table[
                `${user.user_name_last_kor + user.user_name_first_kor}`
              ].push(...res.data);
            } else {
              table[`${user.user_name_last_kor + user.user_name_first_kor}`] =
                res.data;
            }
          }
        }
      }
      downloadExcel(table, item.company_name);
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedItem = null;
    },
    async deleteItemConfirm() {
      await delCompany(this.selectedItem.company_uuid);
      this.dialogDelete = false;
      this.selectedItem = null;
      this.getCompany();
    },
    newCompanyBtn() {
      this.company_frm = {
        company_idx: null,
        company_id: null,
        company_pw: null,
        company_name_first_kor: null,
        company_name_last_kor: null,
        company_name_first_eng: null,
        company_name_last_eng: null,
        company_mail_id: null,
        company_mail_domain: null,
        company_phone_number: null,
      };
      this.dialogNewCompany = true;
    },
    async newCompanySaveBtn() {
      await newCompany(this.company_frm);
      this.dialogNewCompany = false;
      await this.getCompany();
    },
    async editCompanySaveBtn() {
      await putCompany(
        this.edit_company_frm.company_uuid,
        this.edit_company_frm
      );
      await this.getCompany();
      this.dialogEditCompany = false;
    },
    passAutoFill() {
      const str = randomstring.generate(7);
      this.company_frm.company_pw = str;
    },
    editItem(item) {
      this.edit_company_frm = item;
      this.dialogEditCompany = true;
    },
    async allocUsers(item) {
      this.comapny_account_alloc_item = item;
      this.comapny_account_alloc_frm.company_uuid = item.company_uuid;

      this.allocatedUsers = [];
      const data = await getAllocUser(
        this.comapny_account_alloc_item.company_uuid
      );
      if (data.success) {
        this.allocatedUsers = data.data;
      }
      this.dialogAllocUsers = true;
    },
    async newAllocUserDialog() {
      this.dialogAllocUsers_unalloc = true;
      await this.getAccounts();
    },
    async deleteAllocUser(item) {
      await delAllocUser(item.company_uuid, item.user_uuid);
      await this.allocUsers(this.comapny_account_alloc_item);
    },
    async newAllocUser(item) {
      await newAllocUser(
        this.comapny_account_alloc_item.company_uuid,
        item.user_uuid
      );
      await this.allocUsers(this.comapny_account_alloc_item);
      await this.getAccounts();
    },
  },
  data() {
    return {
      companyList: [],
      companyLevelList: [],
      companySearch: "",
      companyHeader: [
        {
          text: "Company ID",
          align: "start",
          sortable: true,
          value: "company_id",
        },
        {
          text: "Account Number",
          align: "start",
          sortable: true,
          value: "company_account_number",
        },
        {
          text: "Company Name",
          align: "start",
          sortable: true,
          value: "company_name",
        },
        {
          text: "회사권한",
          align: "start",
          sortable: false,
          value: "company_level",
        },
        {
          text: "비고",
          align: "start",
          sortable: false,
          value: "company_extends",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      selectedItem: null,

      dialogDelete: false,
      dialogNewCompany: false,
      dialogEditCompany: false,
      dialogAllocUsers: false,
      dialogAllocUsers_unalloc: false,

      company_frm: {
        company_account_number: null,
        company_name: null,
        company_extends: null,
      },
      edit_company_frm: {
        company_account_number: null,
        company_name: null,
        company_extends: null,
      },

      allocatedUsers: [],
      accountSearch: "",
      accountList: [],
      allocatedHeader: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "TB_USER_ACCOUNT_LIST.user_id",
        },
        {
          text: "이름",
          align: "start",
          sortable: false,
          value: "TB_USER_ACCOUNT_LIST.user_name_first_kor",
        },
        {
          text: "성",
          align: "start",
          sortable: false,
          value: "TB_USER_ACCOUNT_LIST.user_name_last_kor",
        },
        {
          text: "연락처",
          align: "start",
          sortable: false,
          value: "TB_USER_ACCOUNT_LIST.user_phone_number",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      accountHeader: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "user_id",
        },
        {
          text: "이름",
          align: "start",
          sortable: false,
          value: "user_name_first_kor",
        },
        {
          text: "성",
          align: "start",
          sortable: false,
          value: "user_name_last_kor",
        },
        {
          text: "연락처",
          align: "start",
          sortable: false,
          value: "user_phone_number",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      comapny_account_alloc_item: {
        company_name: null,
      },
      comapny_account_alloc_frm: {
        company_uuid: null,
        user_uuid: null,
      },
    };
  },
};
</script>
