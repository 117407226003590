<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>관리자 - 사용자 관리</h1>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="#FF4D00" dark block @click="$router.push('/admin')">
          BACK
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">User Account List</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" light @click="newAccountBtn">
              <v-icon>mdi-plus</v-icon>
              New Account
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="userSearch"
                    append-icon="mdi-magnify"
                    label="사용자 이름"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-col>
                  <DataGrid
                    :data="accountList"
                    :filterable="true"
                    :pageSize="15"
                    :pagination="true"
                    :virtualScroll="true"
                    :loading="loading"
                    :clickToEdit="true"
                    editMode="cell"
                    :columnResizing="true"
                    style="width: 100%"
                  >
                    <GridColumn
                      field="user_id"
                      title="ID"
                      align="center"
                      :sortable="true"
                      :rowspan="2"
                    ></GridColumn>
                    <GridColumn
                      field="user_name_first_kor"
                      title="이름"
                      align="center"
                      :sortable="true"
                      :rowspan="2"
                    ></GridColumn>
                    <GridColumn
                      title="Option"
                      :editable="true"
                      :filterable="false"
                      align="center"
                    >
                      <template slot="body" slot-scope="scope">
                        <LinkButton
                          iconCls="icon-search"
                          class="mx-1"
                          @click="goToCase(scope)"
                        >
                          Detail
                        </LinkButton>
                        <LinkButton
                          iconCls="icon-remove"
                          class="mx-1"
                          @click="removeCase(scope)"
                        >
                          Remove
                        </LinkButton>
                      </template>
                    </GridColumn>
                  </DataGrid>
                </v-col> -->
                <v-col>
                  <v-data-table
                    :headers="accountHeader"
                    :items="accountList"
                    class="elevation-0"
                    dense
                    :search="userSearch"
                    :items-per-page="15"
                    height="500px"
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small class="mr-2" @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                      <v-icon small @click="downloadCases(item)">
                        mdi-download
                      </v-icon>
                    </template>
                    <template v-slot:item.Email="{ item }">
                      {{ item.user_mail_id }}@{{ item.user_mail_domain }}
                    </template>
                    <template v-slot:item.user_level="{ item }">
                      {{
                        userLevelList.find(
                          (e) => e.user_level == item.user_level
                        ).level_name
                      }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          <h5>삭제시 복구가 불가능 합니다.<br />정말 삭제 하시겠습니까?</h5>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteItemConfirm">
            삭제
          </v-btn>
          <v-btn color="blue darken-1" text @click="closeDelete">취소</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewAccount" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">New User Account</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pb-2">
              <v-col cols="12"><h2>Account Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="USER ID"
                  class="px-1 pt-0"
                  v-model="user_frm.user_id"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="PASSWORD"
                  class="px-1 pt-0"
                  append-icon="mdi-auto-fix"
                  v-model="user_frm.user_pw"
                  @click:append="passAutoFill"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="user_frm.user_level"
                  outlined
                  light
                  dense
                  hide-details
                  label="권한 선택"
                  :items="userLevelList"
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="user_frm.user_tech_level"
                  outlined
                  light
                  dense
                  hide-details
                  label="기술레벨"
                  :items="tech_levels"
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col cols="12"><h2>User Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR FIRST NAME(KOR:이름)"
                  class="px-1 pt-0"
                  v-model="user_frm.user_name_first_kor"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR LAST NAME(KOR:성)"
                  class="px-1 pt-0"
                  v-model="user_frm.user_name_last_kor"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR FIRST NAME(ENG:이름)"
                  class="px-1 pt-0"
                  v-model="user_frm.user_name_first_eng"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR LAST NAME(ENG:성)"
                  class="px-1 pt-0"
                  v-model="user_frm.user_name_last_eng"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="MAIL ID"
                  class="px-1 pt-0"
                  v-model="user_frm.user_mail_id"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="MAIL DOMAIN"
                  class="px-1 pt-0"
                  prepend-inner-icon="mdi-at"
                  v-model="user_frm.user_mail_domain"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="USER PHONE NUMBER"
                  class="px-1 pt-0"
                  v-model="user_frm.user_phone_number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="#FF4D00" dark block @click="newAccountSaveBtn">
                  SAVE
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditAccount" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">Edit User Account</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pb-2">
              <v-col cols="12"><h2>Account Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="USER ID"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_id"
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="PASSWORD"
                  class="px-1 pt-0"
                  append-icon="mdi-auto-fix"
                  v-model="edit_user_frm.user_pw"
                  @click:append="passAutoFill"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="edit_user_frm.user_level"
                  outlined
                  light
                  dense
                  hide-details
                  label="권한 선택"
                  :items="userLevelList"
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="edit_user_frm.user_tech_level"
                  outlined
                  light
                  dense
                  hide-details
                  label="기술레벨"
                  :items="tech_levels"
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col cols="12"><h2>User Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR FIRST NAME(KOR:이름)"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_name_first_kor"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR LAST NAME(KOR:성)"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_name_last_kor"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR FIRST NAME(ENG:이름)"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_name_first_eng"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR LAST NAME(ENG:성)"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_name_last_eng"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="MAIL ID"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_mail_id"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="MAIL DOMAIN"
                  class="px-1 pt-0"
                  prepend-inner-icon="mdi-at"
                  v-model="edit_user_frm.user_mail_domain"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="USER PHONE NUMBER"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_phone_number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="#FF4D00" dark block @click="editAccountSaveBtn">
                  SAVE
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getAccounts,
  delAccount,
  newAccount,
  putAccount,
} from "@/api/Admin/Accounts";
import { getCaseParams } from "@/api/Case";
import { getUserLevel } from "@/api/Presets";
import randomstring from "randomstring";
import { downloadExcel } from "./utils/downloadExcelForUser";
export default {
  mounted() {
    this.getAccounts();
    this.getUserLevel();
  },
  methods: {
    async getUserLevel() {
      const data = await getUserLevel();
      this.userLevelList = [];
      if (data.success) {
        this.userLevelList = data.data;
        for (const level of this.userLevelList) {
          level.text = level.level_name;
          level.value = level.user_level;
        }
      }
    },
    async getAccounts() {
      const data = await getAccounts();
      this.accountList = [];
      if (data.success) {
        this.accountList = data.data;
      }
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedItem = null;
    },
    async downloadCases(item) {
      const res = await getCaseParams({
        case_write_user_uuid: item.user_uuid,
      });
      if (res.success) {
        downloadExcel(
          res.data,
          item.user_name_last_kor + item.user_name_first_kor
        );
      }
      return res;
    },
    async deleteItemConfirm() {
      await delAccount(this.selectedItem.user_uuid);
      this.dialogDelete = false;
      this.selectedItem = null;
      this.getAccounts();
    },
    newAccountBtn() {
      this.user_frm = {
        user_idx: null,
        user_id: null,
        user_pw: null,
        user_name_first_kor: null,
        user_name_last_kor: null,
        user_name_first_eng: null,
        user_name_last_eng: null,
        user_mail_id: null,
        user_mail_domain: null,
        user_phone_number: null,
        user_tech_level: "🔧",
      };
      this.dialogNewAccount = true;
    },
    async newAccountSaveBtn() {
      await newAccount(this.user_frm);
      this.dialogNewAccount = false;
      await this.getAccounts();
    },
    passAutoFill() {
      const str = randomstring.generate(7);
      this.user_frm.user_pw = str;
    },
    editItem(item) {
      this.edit_user_frm = item;
      this.edit_user_frm.user_pw = null;
      this.dialogEditAccount = true;
    },
    async editAccountSaveBtn() {
      await putAccount(this.edit_user_frm.user_uuid, this.edit_user_frm);
      this.dialogEditAccount = false;
      await this.getAccounts();
    },
  },
  data() {
    return {
      accountList: [],
      userLevelList: [],
      userSearch: "",
      accountHeader: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "user_id",
        },
        {
          text: "이름",
          align: "start",
          sortable: false,
          value: "user_name_first_kor",
        },
        {
          text: "성",
          align: "start",
          sortable: false,
          value: "user_name_last_kor",
        },
        {
          text: "전화번호",
          align: "start",
          sortable: false,
          value: "user_phone_number",
        },
        { text: "Email", value: "Email", sortable: false },
        {
          text: "권한",
          align: "start",
          sortable: false,
          value: "user_level",
        },
        {
          text: "기술레벨",
          align: "start",
          sortable: false,
          value: "user_tech_level",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      selectedItem: null,

      dialogDelete: false,
      dialogNewAccount: false,
      dialogEditAccount: false,
      tech_levels: [
        {
          text: "🔧 Support Technician",
          value: "🔧",
        },
        {
          text: "🖥️ Junior Support Enginner",
          value: "🖥️",
        },
        {
          text: "💡 Support Enginner",
          value: "💡",
        },
        {
          text: "🌐 Senior Support Enginner",
          value: "🌐",
        },
        {
          text: "🏛️ Principal Support Enginner",
          value: "🏛️",
        },
      ],
      user_frm: {
        user_idx: null,
        user_id: null,
        user_pw: null,
        user_name_first_kor: null,
        user_name_last_kor: null,
        user_name_first_eng: null,
        user_name_last_eng: null,
        user_mail_id: null,
        user_mail_domain: null,
        user_phone_number: null,
        user_level: null,
        user_tech_level: "🔧",
      },
      edit_user_frm: {
        user_idx: null,
        user_id: null,
        user_pw: null,
        user_name_first_kor: null,
        user_name_last_kor: null,
        user_name_first_eng: null,
        user_name_last_eng: null,
        user_mail_id: null,
        user_mail_domain: null,
        user_phone_number: null,
        user_level: null,
        user_tech_level: "🔧",
      },
    };
  },
};
</script>
